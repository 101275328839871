import React from 'react';
import { Skeleton } from 'antd';
import { useTheme } from 'styled-components';
import { PaywallModal } from '@helloworld1812/hr-ui';
import { useCurrentUserInfo } from '@helloworld1812/ws-biz-hooks';

import upsellBanner from '@/assets/hiring-paywall-banner.svg';

import { HiringPaywallProps } from './hiring-paywall.types';

export const HiringPaywallModal: React.FC<React.PropsWithChildren<HiringPaywallProps>> = ({
  visible = true,
  maskClosable = false,
  closeIcon = false,
  onCancel,
  isCFA,
  isLoading,
}) => {
  const { data: user } = useCurrentUserInfo();
  const theme = useTheme();

  return (
    <PaywallModal
      isOpen={visible}
      isCFA={isCFA}
      isLoading={isLoading}
      maskClosable={maskClosable}
      onClose={() => {
        if (onCancel) {
          onCancel();
        }
      }}
      closeIcon={closeIcon}
      portalId="4625906"
      formId="4c82db74-88d2-4a36-ad5e-4b265668b0c4"
      customerId={user?.company?.customer_id || 0}
      companyName={user?.company?.name || ''}
      footerLink={{
        src: 'https://www.workstream.us/product/hiring',
        text: 'Learn more',
        type: 'button',
      }}
      bannerSrc={upsellBanner}
      bannerImgStyle={{ height: '298px', width: '700px' }}
      confirmationText="We'll be in touch soon to share more about Hiring."
      confirmationTitle="Thanks for your interest!"
      caption="UPGRADE TO WORKSTREAM HIRING"
      title="Find and hire the right applicants, faster"
      bulletPoints={[
        'Attract applicants who are interested in your position, not just any position.',
        'Automatically advance qualified applicants, and avoid screening unqualified candidates',
        'Save days on tedious back and forth by automating scheduling for on-site interviews',
      ]}
      zIndex={theme.zIndex.drawer - 10}
    />
  );
};

export const HiringPaywall: React.FC<React.PropsWithChildren<HiringPaywallProps>> = (props) => {
  return (
    <>
      <Skeleton />
      <HiringPaywallModal {...props} />
    </>
  );
};
